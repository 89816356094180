import React from "react"


import { 
     EuiPage,
     EuiTitle,
     EuiPageContent,
     EuiSpacer,
     EuiPageSideBar,
     EuiPanel,
     EuiText,
     EuiPageContentBody,
     EuiPageHeader,
     EuiPageBody,
    } from '@elastic/eui';
    
import FullSidebarMenu from './../components/full_sidebar_menu';
import EmptyResult from '../components/empty_result';
import BrokerCard from '../components/broker_card';
import Search from './../components/search';

import Layout from "../components/layout";
import SEO from "../components/seo";

import { searchStore } from './../apollo/client';

export default () => {

  const [results, setResults] = React.useState([]);
  const [brokersRender, setBrokersRender] = React.useState([]);
  

  searchStore({
      data: results,
      update: (data) => { setResults(data) }
  })

  //const searchResult = searchStore().data;

  React.useEffect(() => {
    setBrokersRender( 
      results.map((element) => {
          return <BrokerCard key={element.item.slug} data={element.item} />
      })  
    )
  
  }, [results])


  return <Layout>
    <SEO 
      title="Поиск по сайту"
      canonical="/search"
    />

    <EuiPage restrictWidth={1230} >
    <EuiPageSideBar style={{ minWidth: 270 }}>


    <div className="hide_m hide_l hide_xl">
          <EuiPanel>
            <Search />
          </EuiPanel>
          <EuiSpacer size="s" />
    </div>     

      <EuiPanel className="hide_xs hide_s sticky">
            <EuiText><h3>Все категории:</h3></EuiText>
              <EuiSpacer size="s" />
              <FullSidebarMenu />
        </EuiPanel>
          

    </EuiPageSideBar>
    
    <EuiPageBody>
      {/* <EuiPageHeader>
        <EuiTitle size="m">
              <h1>Результаты поиска:</h1>
            </EuiTitle>
       </EuiPageHeader> */}
       
      <EuiPageContent>    
        <EuiPageContentBody>
          {results.length > 0 ? brokersRender : <EmptyResult for="search" />}
        </EuiPageContentBody>
      </EuiPageContent>
    </EuiPageBody>
    
    </EuiPage>
  </Layout>
}







